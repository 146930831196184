import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import tmr1 from '../../images/prayer-shape/2.png';
import tmr2 from '../../images/prayer-shape/1.png';
import tmr3 from '../../images/prayer-shape/3.png';
import VideoModal from '../ModalVideo';
import { CityContext } from '../CityContext/CityContext';
import './style.css';

const About = (props) => {
  // CityContext'ten global verileri alıyoruz
  const { selectedCity, selectedDistrict, prayerTimes } = useContext(CityContext);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="wpo-about-area section-padding">
      <div className="container">
        <div className="timer-section">
          <div className="row">
            <div className="col-lg-5">
              <div className="timer-text">
                <h1>{selectedCity} {selectedDistrict} Namaz Saatleri</h1>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="timer-num">
                <ul>
                  <li>
                    İmsak<span>{prayerTimes.Fajr || '...'}</span>
                  </li>
                  <li>
                    Sabah<span>{prayerTimes.Sunrise || '...'}</span>
                  </li>
                  <li>
                    Öğle<span>{prayerTimes.Dhuhr || '...'}</span>
                  </li>
                  <li>
                    İkindi<span>{prayerTimes.Asr || '...'}</span>
                  </li>
                  <li>
                    Akşam<span>{prayerTimes.Maghrib || '...'}</span>
                  </li>
                  <li>
                    Yatsı<span>{prayerTimes.Isha || '...'}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="wpo-about-img-3">
                <img src={props.aboutImg} alt="Ramazan Ayında Okunacak Dualar" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 colsm-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title">
                  <span>Dualar</span>
                  <h1>Ramazan Ayında Okunacak Dualar</h1>
                </div>
                <p>
                Ramazan ayında yapılan dualar, kalbi arındırır ve Allah'a yakınlaşmayı sağlar. Bu mübarek ayda sabır, affedici olmak ve rahmet dilemek için sıkça edilen dualar, ruhumuzu güçlendirir ve manevi huzur getirir.
                </p>
                <div className="btns">
                  <Link onClick={ClickHandler} to="/dualar" className="theme-btn" tabIndex="0">
                    Dualar'ı Gör
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default About;
