import React, { useContext, useEffect, useState } from 'react';
import { CityContext } from '../CityContext/CityContext';
import FloatingCityBubble from '../FloatingCityBubble';
import './style.css';

const Hero = (props) => {
  const {
    cities,
    selectedCity,
    setSelectedCity,
    selectedDistrict,
    setSelectedDistrict,
  } = useContext(CityContext);

  const citiesMapping = cities || {};

  // Eğer mapping yüklenmişse ve "Istanbul" ile "Fatih" mevcutsa, default olarak onları zorla ayarla.
  useEffect(() => {
    if (citiesMapping["Istanbul"] && citiesMapping["Istanbul"].includes("Fatih")) {
      if (selectedCity !== "Istanbul" || selectedDistrict !== "Fatih") {
        setSelectedCity("Istanbul");
        setSelectedDistrict("Fatih");
      }
    }
  }, [citiesMapping, selectedCity, selectedDistrict, setSelectedCity, setSelectedDistrict]);

  // Geri sayım ve namaz vakitleri için state'ler
  const [iftarTime, setIftarTime] = useState('');
  const [iftarCountdown, setIftarCountdown] = useState('');
  const [progress, setProgress] = useState(0);
  const [prayerTimes, setPrayerTimes] = useState({});
  const [countdownMessage, setCountdownMessage] = useState('İftara Kalan Süre');
  const [greenBarMessage, setGreenBarMessage] = useState("");
  const [showGreenBar, setShowGreenBar] = useState(false);

  // Floating city bubble & dropdown state'leri
  const [showFloatingCityBubble, setShowFloatingCityBubble] = useState(false);
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  useEffect(() => {
    if (!selectedCity || !selectedDistrict) return;
    fetch(`https://api.aladhan.com/v1/timingsByCity?city=${selectedDistrict}&country=Turkey&method=13`)
      .then(response => response.json())
      .then(data => {
        if (data.data && data.data.timings) {
          setPrayerTimes(data.data.timings);
          setIftarTime(data.data.timings.Maghrib);
        }
      })
      .catch(err => console.error(err));
  }, [selectedCity, selectedDistrict]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateCountdown();
      updateProgressBar();
    }, 1000);
    return () => clearInterval(interval);
  }, [iftarTime, prayerTimes]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingCityBubble(true);
      } else {
        setShowFloatingCityBubble(false);
        setShowCityDropdown(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const updateCountdown = () => {
    if (!prayerTimes.Maghrib || !prayerTimes.Fajr) return;
    let now = new Date();
    let today = now.toDateString();
    let iftarToday = new Date(`${today} ${prayerTimes.Maghrib}`);
    let imsakToday = new Date(`${today} ${prayerTimes.Fajr}`);
    let targetTime;
    let countMsg = "";
    
    if (now < imsakToday) {
      targetTime = imsakToday;
      countMsg = "İmsak'a Kalan Süre";
    } else if (now < iftarToday) {
      targetTime = iftarToday;
      countMsg = "İftara Kalan Süre";
    } else {
      let tomorrow = new Date(now);
      tomorrow.setDate(now.getDate() + 1);
      targetTime = new Date(`${tomorrow.toDateString()} ${prayerTimes.Fajr}`);
      countMsg = "İmsak'a Kalan Süre";
    }
    setCountdownMessage(countMsg);

    let greenMsg = "";
    let specialGreenBar = false;
    if (now >= imsakToday && now < imsakToday.getTime() + 30 * 60 * 1000) {
      greenMsg = "için İmsak Saati Geçti Hayırlı Oruçlar";
      specialGreenBar = true;
    } else if (now >= iftarToday && now < iftarToday.getTime() + 30 * 60 * 1000) {
      greenMsg = "için İftar Vakti Allah Kabul Etsin";
      specialGreenBar = true;
    }
    setGreenBarMessage(greenMsg);
    setShowGreenBar(specialGreenBar);

    let diff = targetTime - now;
    let hours = Math.floor(diff / (1000 * 60 * 60));
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((diff % (1000 * 60)) / 1000);
    setIftarCountdown(`${hours} saat ${minutes} dakika ${seconds} saniye`);
  };

  const updateProgressBar = () => {
    let now = new Date();
    let target = new Date(`${now.toDateString()} ${iftarTime}`);
    let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    let totalTime = target - startOfDay;
    let elapsedTime = now - startOfDay;
    let progressPercentage = Math.min((elapsedTime / totalTime) * 100, 100);
    setProgress(progressPercentage);
  };

  return (
    <section className={`hero ${props.HeroStyleClass}`}>
      {showGreenBar && (
        <div className="green-bar">
          <p>{selectedCity} {selectedDistrict} için {greenBarMessage}</p>
        </div>
      )}
      <div className="hero-slider">
        <div className="slide">
          <div className="container">
            <div className="row">
              <div className="col col-lg-7 slide-caption">
                <div className="city-selector">
                  <h4>Şehir Seç</h4>
                  <select
                    value={selectedCity}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      setSelectedDistrict(citiesMapping[e.target.value][0]);
                    }}
                  >
                    {Object.keys(citiesMapping).map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                  <select
                    value={selectedDistrict}
                    onChange={(e) => setSelectedDistrict(e.target.value)}
                  >
                    {(citiesMapping[selectedCity] || []).map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="right-vec">
            <div className="sayac">
              <h2>
                {selectedCity} {selectedDistrict} {countdownMessage}
              </h2>
              <p>
                <span>{iftarCountdown || 'Yükleniyor...'}</span>
              </p>
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
            <div className="right-border">
              <div className="right-icon">
                <i className="fi flaticon-quran"></i>
              </div>
              <div className="right-icon">
                <i className="fi flaticon-taj-mahal-1"></i>
              </div>
              <div className="right-icon">
                <i className="fi flaticon-allah-word"></i>
              </div>
              <div className="right-icon">
                <i className="fi flaticon-muhammad-word"></i>
              </div>
              <div className="right-icon">
                <i className="fi flaticon-prayer"></i>
              </div>
              <div className="right-icon righticon3">
                <i className="fi flaticon-business-and-finance"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showFloatingCityBubble && (
        <FloatingCityBubble
          selectedCity={selectedCity}
          selectedDistrict={selectedDistrict}
          cities={citiesMapping}
          onCityChange={(e) => {
            setSelectedCity(e.target.value);
            setSelectedDistrict(citiesMapping[e.target.value][0]);
          }}
          onDistrictChange={(e) => setSelectedDistrict(e.target.value)}
        />
      )}
    </section>
  );
};

export default Hero;
