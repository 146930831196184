import React, { useContext } from 'react';
import './style.css';
import { CityContext } from '../CityContext/CityContext';

const Support1 = () => {
  const { selectedCity, selectedDistrict } = useContext(CityContext); // Seçili il ve ilçeyi al

  return (
    <section className="support-curved-area">
      <div className="support-curved-bg">
        {/* İsteğe bağlı ufak simgeler, mutlak konumlu */}
        <span className="floating-icon icon-1"></span>
        <span className="floating-icon icon-2"></span>
        <span className="floating-icon icon-3"></span>

        <div className="support-curved-content">
          <p>
            2025 Ramazan ayı için iftar saatleri ve sahur saatlerini
            <br />
            İmsakiye, ramazan ayında oruç tutacaklar için iftar vakti ve sahur vakti bilgilerini kolayca edinmelerine olanak tanımaktadır. 2025 Ramazan imsakiyesi ilden ile farklılık göstermektedir. Bugün {selectedCity} {selectedDistrict} iftar vakti 2025 saat kaçta ve {selectedCity} {selectedDistrict} sahur vakti 2025 ne zaman gibi soruların bilgilerine Hürriyet {selectedCity} {selectedDistrict} İmsakiye 2025 sayfası üzerinden Diyanet'in sunduğu verilere erişebilirsiniz. Bilindiği üzere İmsak, sabah namazı ve orucun başlama vaktini göstermektedir. Yatsı namazından sonra Teravih namazı kılınmaktadır. Ek olarak sayfamız üzerinden sahurun bitmesine ne kadar kaldı ve iftara ne kadar var geri sayım ile kalan süre güncel olarak takip edilebilir.
          </p>

          <p>
          <br />
          <h3>{selectedCity} {selectedDistrict} İftar Vakti Saat Kaçta?</h3>

          {selectedCity} {selectedDistrict} iftar vakti 2025 yılının Ramazan orucunu tutanlar tarafından gün gün takip edilmektedir. Oruç ibadetini layığıyla yerine getirebilmek için orucun farzlarını uygulamak gerekir. Orucun başlama ve bitiş saati güneşin doğuşu ve batışına göre tespit edildiği için gün gün imsak ve iftar saati dikkatle incelenmelidir. Bu noktada; Diyanet İşleri Başkanlığı tarafından yayınlanan 30 günlük imsakiye ile 2025 iftar saatleri belli oldu. Bu sayfadan 2025 Ramazan İmsakiyesi ile iftar saat kaçta ve iftara ne kadar kaldı sorusuna yanıt bulabilirsiniz.
          <br />
          <h3>{selectedCity} {selectedDistrict} Sahur (İmsak) Saati Saat Kaçta?</h3>

          Sahur, oruca niyet etmeden önce o günkü oruç için gereken hazırlığı yaptığımız vakittir. Bu zaman dilimini kaçırmamak adına {selectedCity} {selectedDistrict} sahur vakti hakkında bilgi edinmek gerekir. Bu saat her gün değişiklik gösterdiğinden sabah ezanının okunacağı saati merak edenler ve sahur saat kaçta sorusuna yanıt arayanlar {selectedCity} {selectedDistrict} için imsak vakitleri bilgisine sabah.com.tr İmsakiye sayfasından ulaşabilir.
          <br />
          <h3>{selectedCity} {selectedDistrict} Teravih Namazı Saat Kaçta?</h3>

          Ramazan ayı boyunca camilerde cemaat halinde kılınan teravih namazı saati de İmsakiye ile yanıt bulan konular arasında yer alır. Yatsı namazının hemen ardından eda edilen teravih namazı saat kaçta kılınacak sorusunun yanıtı için {selectedCity} {selectedDistrict} İmsakiye 2025 ekranına göz atabilirsiniz.
          <br />
          <a href='/ramazan-rehberi/teravih-namazi-nasil-kilinir'>Teravih Namazı Nasıl Kılınır?</a>
          <br />
          <h3>Kadir Gecesi Ne Zaman?</h3>

          Diyanet İşleri Başkanlığı'nın 2025 yılı dini takvimine göre, Kadir Gecesi bu yıl 26 Mart 2025 Çarşamba günü idrak edilecek. <br /><a href="/dualar-ve-hadisler/kadir-gecesi-dualari">Kadir Gecesinde okunacak dualar</a>
      
          <h3> Ramazan Bayramı Ne Zaman?</h3>

          Ramazan Bayramı 1. Gün | 30 Mart Pazar 2025
          Ramazan Bayramı 2. Gün | 31 Mart Pazartesi 2025
          Ramazan Bayramı 3. Gün | 1 Nisan Salı 2025
          <br />
          <a href='/ramazan-rehberi/bayram-namazi-nasil-kilinir'>Bayram Namazı Nasıl Kılınır?</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Support1;
