import React, { useState, useEffect } from 'react';
import './style.css';

// API'den namaz vakitlerini çeken yardımcı fonksiyon
const fetchPrayerTimes = async (city) => {
  const response = await fetch(
    `https://api.aladhan.com/v1/timingsByCity?city=${city}&country=Turkey&method=13`
  );
  const data = await response.json();
  if (data.code === 200) {
    return data.data.timings;
  } else {
    throw new Error("API error");
  }
};

// "+03" gibi ek bilgileri kaldıran yardımcı fonksiyon
const formatTime = (timeStr) => {
  if (!timeStr) return '';
  return timeStr.split(' ')[0];
};

// BigCityPrayer bileşeni: Her şehir için aktif vakit (iftar ya da imsak) ve geri sayımı gösterir.
const BigCityPrayer = ({ city, img }) => {
  const [prayerTime, setPrayerTime] = useState({});
  const [activeLabel, setActiveLabel] = useState(""); // "İftar:" veya "İmsak:"
  const [activeTime, setActiveTime] = useState("");   // Aktif vakit (sadece saat)
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const getPrayerTimes = async () => {
      try {
        const timings = await fetchPrayerTimes(city);
        setPrayerTime(timings);
      } catch (err) {
        console.error(err);
      }
    };
    getPrayerTimes();
  }, [city]);

  useEffect(() => {
    if (!prayerTime.Maghrib || !prayerTime.Fajr) return;

    const updateCountdown = () => {
      const now = new Date();
      const today = now.toDateString();
      const iftarToday = new Date(`${today} ${prayerTime.Maghrib}`);
      const imsakToday = new Date(`${today} ${prayerTime.Fajr}`);
      let targetTime;
      let label;

      // Eğer iftar zamanı henüz gelmediyse, aktif vakit iftar; 
      // eğer iftar geçtiyse, aktif vakit gelecek günün imsak vaktidir.
      if (now < iftarToday) {
        targetTime = iftarToday;
        label = "İftar:";
      } else {
        let tomorrow = new Date(now);
        tomorrow.setDate(now.getDate() + 1);
        targetTime = new Date(`${tomorrow.toDateString()} ${prayerTime.Fajr}`);
        label = "İmsak:";
      }
      setActiveLabel(label);
      // Aktif vakit, API'den gelen orijinal vakitten "+03" kısmını kaldırarak gösterilsin.
      setActiveTime(formatTime(label === "İftar:" ? prayerTime.Maghrib : prayerTime.Fajr));

      const diff = targetTime - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setCountdown(`${hours} saat ${minutes} dakika ${seconds} saniye`);
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [prayerTime]);

  return (
    
    <div className="grid">
      <div className="pilars-item">
        <div className="pilars-img">
          <img src={img} alt={city} />
        </div>
        <div className="pilars-text">
          <h2>{city}</h2>
          <p>
            <strong>{activeLabel}</strong> {activeTime} <br />
            <span>{countdown}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const Pilars = () => {
  // Büyükşehirlerin listesini ve ilgili görselleri
  const bigCities = [
    { 
      city: "Istanbul", 
      img: require('../../images/pilars/istanbul-iftar-vakti.webp'),
      alt: "İstanbul için iftar vakti"
  },
  { 
      city: "Ankara", 
      img: require('../../images/pilars/ankara_iftar_vakti.webp'),
      alt: "Ankara için iftar vakti"
  },
  { 
      city: "Izmir", 
      img: require('../../images/pilars/izmir_iftar_vakti.webp'),
      alt: "İzmir için iftar vakti"
  },
  { 
      city: "Samsun", 
      img: require('../../images/pilars/samsun_iftar_vakti.webp'),
      alt: "Samsun için iftar vakti"
  },
  { 
      city: "Diyarbakır", 
      img: require('../../images/pilars/diyarbakır_iftar_vakti.webp'),
      alt: "Diyarbakır için iftar vakti"
  }
  ];

  return (
    <div className="pilars-area">
        <h1>Büyükşehirlerde İftara / İmsaka Kalan Süre</h1>
      <div className="fluid-container">
        <div className="row-grid">
          {bigCities.map((item, index) => (
            <BigCityPrayer key={index} city={item.city} img={item.img} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pilars;
