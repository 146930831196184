import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import Course from '../../components/Course'
import EventSection from '../../components/event'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import abimg from '../../images/about3.png'
import evn1 from '../../images/event/img-3.jpg'
import evn2 from '../../images/event/img-4.jpg'
import { Helmet } from "react-helmet-async";


const AboutPage =() => {
    return(
        <Fragment>
             <div>
                <Helmet>
                    <title>İmsak.net - Hakkımızda</title>
                    <meta name="description" content="Biz Kimiz? İmsak.net olarak, Ramazan ayının manevi atmosferini dijital dünyada yaşatmayı amaçlayan bir platformuz." />
                    <meta name="keywords" content="imsak.net kimdir, biz kimiz, hakkımızda" />
                    <meta name="author" content="imsak.net" />
                    <meta name="robots" content="index, follow" />
                    <link rel="canonical" href="https://imsak.net/hakkimizda/" />
	                <meta property="og:locale" content="tr_TR" />
	                <meta property="og:type" content="website" />
	                <meta property="og:title" content="İmsak.net - Hakkımızda" />
	                <meta property="og:description" content="Biz Kimiz?İmsak.net olarak, Ramazan ayının manevi atmosferini dijital dünyada yaşatmayı amaçlayan bir platformuz." />
	                <meta property="og:url" content="https://imsak.net/hakkimizda/" />
	                <meta property="og:site_name" content="İmsak.net" />
                </Helmet>
            </div>
            <Navbar/>
            <PageTitle pageTitle={'Hakkımızda'}/> 
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AboutPage;
