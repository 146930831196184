import React from "react";
import { HelmetProvider } from "react-helmet-async";
import QuranComponent from "../../components/QuranComponent";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from '../../components/scrollbar';


const QuranPage = () => {
  return (
    <div>
      <HelmetProvider >
        <title>Kur'an-ı Kerim ve Türkçe Meali</title>
        </HelmetProvider >
        <Navbar />
        <QuranComponent />
        <Footer />
        <Scrollbar />
      
    </div>
  );
};

export default QuranPage;
