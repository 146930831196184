import React, { useState, useEffect } from "react";
import QuranUI from "./QuranUI";
import { fetchSurahList, fetchSurahAyahs } from "./QuranAPI";
import "./QuranStyles.css";

const QuranComponent = () => {
  const [surahList, setSurahList] = useState([]);
  const [selectedSurah, setSelectedSurah] = useState(1);
  const [selectedAyahs, setSelectedAyahs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSurahList = async () => {
      const surahs = await fetchSurahList();
      setSurahList(surahs);
    };
    loadSurahList();
  }, []);

  useEffect(() => {
    const loadSurahAyahs = async () => {
      setLoading(true);
      const ayahs = await fetchSurahAyahs(selectedSurah);
      console.log("🔍 UI'ya Gelen Ayetler:", ayahs);
      setSelectedAyahs(ayahs);
      setLoading(false);
    };
    loadSurahAyahs();
  }, [selectedSurah]);

  return (
    <QuranUI
      surahList={surahList}
      selectedSurah={selectedSurah}
      setSelectedSurah={setSelectedSurah}
      selectedAyahs={selectedAyahs}
      loading={loading}
    />
  );
};

export default QuranComponent;
