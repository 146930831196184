import React, { useState, useEffect, useRef } from "react";

const QuranUI = ({ surahList, selectedSurah, setSelectedSurah, selectedAyahs, loading }) => {
  const [currentAyah, setCurrentAyah] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [progress, setProgress] = useState(0); // 🎯 Ses ilerleme yüzdesi
  const audioRef = useRef(null); // 🎯 Ses için referans

  if (selectedAyahs.length === 0) {
    return <p className="error-message">⚠️ Ayetler yüklenemedi. Lütfen tekrar deneyin.</p>;
  }

  const currentVerse = selectedAyahs[currentAyah];

  // 📌 Ses Çalma Fonksiyonu
  const playAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
    }
    const audio = new Audio(currentVerse.audio);
    audioRef.current = audio;

    audio.ontimeupdate = () => {
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    audio.onended = () => {
      setProgress(100);
    };

    setCurrentAudio(audio);
    audio.play();
  };

  // 📌 Ses Durdurma Fonksiyonu
  const pauseAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
    }
  };

  // 📌 Baştan Çalma Fonksiyonu
  const restartAudio = () => {
    if (currentAudio) {
      currentAudio.currentTime = 0;
      currentAudio.play();
    }
  };

  return (
    <div className="quran-container">
      {/* 📌 Üst Menü */}
      <div className="top-bar">
        <select onChange={(e) => setSelectedSurah(Number(e.target.value))} value={selectedSurah}>
          {surahList.map((surah) => (
            <option key={surah.id} value={surah.id}>
              {surah.id}. {surah.name}
            </option>
          ))}
        </select>

        <div className="ayah-selector">
          <button disabled={currentAyah === 0} onClick={() => setCurrentAyah(currentAyah - 1)}>←</button>
          <input
            type="number"
            min="1"
            max={selectedAyahs.length}
            value={currentAyah + 1}
            onChange={(e) => {
              let ayahNum = Number(e.target.value) - 1;
              if (ayahNum >= 0 && ayahNum < selectedAyahs.length) {
                setCurrentAyah(ayahNum);
              }
            }}
          />
          <button disabled={currentAyah === selectedAyahs.length - 1} onClick={() => setCurrentAyah(currentAyah + 1)}>→</button>
        </div>
      </div>

      {/* 📌 Ayet Bilgisi */}
      <div className="verse-content">
        <h3 className="verse-translation">{currentVerse.translation}</h3>

        {/* 📌 Sesin ilerleme durumuna göre vurgulanan ayet */}
        <p className="arabic-text">
          {currentVerse.arabic.split(" ").map((word, index) => (
            <span
              key={index}
              className={progress > (index / currentVerse.arabic.split(" ").length) * 100 ? "highlight" : ""}
            >
              {word}{" "}
            </span>
          ))}
        </p>

        <p className="transliteration">{currentVerse.transliteration}</p>

        {/* 📌 Ses Kontrolleri */}
        <div className="audio-controls">
          <button onClick={playAudio}>📢 Başlat</button>
          <button onClick={pauseAudio}>⏸ Durdur</button>
          <button onClick={restartAudio}>🔄 Baştan Çal</button>
        </div>

        {/* 📌 Ses Zaman Çizelgesi */}
        <div className="audio-progress">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      </div>

      {/* 📌 Dinamik Kelime Anlamları Tablosu */}
      {currentVerse.words.length > 0 && (
        <div className="word-analysis">
          <h4>Kelime Anlamları</h4>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Arapça</th>
                <th>Türkçe</th>
                <th>Kök (Arapça)</th>
              </tr>
            </thead>
            <tbody>
              {currentVerse.words.map((word, index) => (
                <tr key={word.id}>
                  <td>{index + 1}</td>
                  <td>{word.arabic}</td>
                  <td>{word.turkish || "Bilinmiyor"}</td>
                  <td>{word.root ? word.root.arabic : "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default QuranUI;
