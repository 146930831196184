const BASE_URL = "https://api.acikkuran.com";

// 📌 UUID Üretmek İçin Alternatif Fonksiyon (Tarayıcı Desteği Yoksa)
const generateUUID = () => {
  return 'xxxx-xxxx-4xxx-yxxx-xxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

// 📌 Sure Listesini Getir
export const fetchSurahList = async () => {
  try {
    const response = await fetch(`${BASE_URL}/surahs`);
    const data = await response.json();
    console.log("📖 Sure Listesi:", data);
    return data.data || [];
  } catch (error) {
    console.error("❌ Sureler alınırken hata oluştu:", error);
    return [];
  }
};

// 📌 Seçilen Sureye Göre Ayetleri ve Kelime Anlamlarını Getir
export const fetchSurahAyahs = async (surahId) => {
  try {
    console.log(`🔍 ${surahId}. Sure için ayetler çekiliyor...`);

    // Önce surenin kaç ayet içerdiğini öğrenelim
    const surahResponse = await fetch(`${BASE_URL}/surah/${surahId}`);
    const surahData = await surahResponse.json();

    if (!surahData || !surahData.data) {
      console.error(`❌ Sure bilgisi alınamadı: ${surahId}`);
      return [];
    }

    const totalVerses = surahData.data.verse_count; // API'den gelen ayet sayısı

    // 📌 **Ayetleri Paralel Olarak Çekme**
    const ayetFetchPromises = [];
    for (let i = 1; i <= totalVerses; i++) {
      ayetFetchPromises.push(
        fetch(`${BASE_URL}/surah/${surahId}/verse/${i}`)
          .then(res => res.json())
      );
    }

    // 📌 **Tüm Ayetleri API’den Paralel Olarak Getir**
    const ayetVerileri = await Promise.all(ayetFetchPromises);

    // 📌 **Ayet İçin Kelime Anlamlarını API’den Çekme**
    const ayetler = await Promise.all(ayetVerileri.map(async (verseData) => {
      if (!verseData.data) return null;

      let kelimeAnlamlari = [];

      try {
        // API'den kelime anlamlarını çek
        const wordResponse = await fetch(`${BASE_URL}/surah/${surahId}/verse/${verseData.data.verse_number}/words`);
        const wordData = await wordResponse.json();

        if (wordData && wordData.data) {
          kelimeAnlamlari = wordData.data;
        }
      } catch (err) {
        console.warn(`⚠️ Kelime anlamları alınamadı: Sure ${surahId}, Ayet ${verseData.data.verse_number}`);
      }

      return {
        id: generateUUID(),
        arabic: verseData.data.verse, // Arapça metin
        translation: verseData.data.translation.text, // Meal
        transliteration: verseData.data.transcription, // Okunuş
        audio: verseData.data.surah.audio.mp3, // MP3 ses dosyası
        words: kelimeAnlamlari // 📌 **Kelime Anlamları API’den Alınıyor**
      };
    }));

    return ayetler.filter(ayet => ayet !== null);
  } catch (error) {
    console.error(`🚨 API Hatası (Sure: ${surahId}):`, error);
    return [];
  }
};
