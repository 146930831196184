import React, { createContext, useState, useEffect } from 'react';
import citiesData from '../../data/cities.json';
import districtsData from '../../data/districts.json';

export const CityContext = createContext();

const createCityMapping = (citiesData, districtsData) => {
  const mapping = {};
  citiesData.forEach((city) => {
    const cityName = city.baslik; // Örneğin "İstanbul", "Adana" vs.
    const cityId = city.id;        // Örneğin "1", "2", ...
    const districts = districtsData
      .filter((district) => district.ilid === cityId)
      .map((district) => district.baslik);
    mapping[cityName] = districts;
  });
  console.log("Mapping:", mapping);
  return mapping;
};

export const CityProvider = ({ children }) => {
  const [cities, setCities] = useState({});
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [prayerTimes, setPrayerTimes] = useState({});
  const [iftarTime, setIftarTime] = useState('');

  useEffect(() => {
    const mapping = createCityMapping(citiesData, districtsData);
    setCities(mapping);
    // Varsayılan olarak İstanbul varsa ve ilçeleri arasında Fatih varsa, onu zorla atayalım.
    if (mapping["İstanbul"] && mapping["İstanbul"].includes("Fatih")) {
      setSelectedCity("İstanbul");
      setSelectedDistrict("Fatih");
    } else {
      // Eğer İstanbul/Fatih yoksa, mapping'in ilk key'ini kullan
      const defaultCity = Object.keys(mapping)[0] || '';
      setSelectedCity(defaultCity);
      setSelectedDistrict(defaultCity && mapping[defaultCity].length > 0 ? mapping[defaultCity][0] : '');
    }
  }, []);

  useEffect(() => {
    if (!selectedCity || !selectedDistrict) return;
    // İlçeyi "/" varsa ilk kısmını alıyoruz.
    const effectiveDistrict = selectedDistrict.split('/')[0].trim();
    fetch(`https://api.aladhan.com/v1/timingsByCity?city=${effectiveDistrict}&country=Turkey&method=13`)
      .then(response => response.json())
      .then(data => {
        if (data.data && data.data.timings) {
          setPrayerTimes(data.data.timings);
          setIftarTime(data.data.timings.Maghrib);
        }
      })
      .catch(err => console.error(err));
  }, [selectedCity, selectedDistrict]);

  return (
    <CityContext.Provider
      value={{
        cities,
        selectedCity,
        setSelectedCity,
        selectedDistrict,
        setSelectedDistrict,
        prayerTimes,
        iftarTime,
      }}
    >
      {children}
    </CityContext.Provider>
  );
};
