import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from '../HomePage';
import Homepage2 from '../HomePage2';
import Homepage3 from '../HomePage3';
import Homepage4 from '../HomePage4';
import AboutPage from '../AboutPage';
import ServicePage from '../ServicePage';
import ShopPage from '../ShopPage';
import ShopSinglePage from '../ShopSinglePage';
import RamazanPage from '../RamazanPage';
import EventPage from '../EventPage';
import EventPageSingle from '../EventPageSingle';
import DonatePage from '../DonatePage';
import BlogPage from '../BlogPage';
import BlogPageLeft from '../BlogPageLeft';
import BlogPageFullwidth from '../BlogPageFullwidth';
import BlogDetails from '../BlogDetails';
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide';
import BlogDetailsFull from '../BlogDetailsFull';
import ErrorPage from '../ErrorPage';
import ContactPage from '../ContactPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';
import { CityProvider } from '../../components/CityContext/CityContext';
import { HelmetProvider } from "react-helmet-async";
import QuranPage from '../QuranPage';

const AllRoute = () => {
  return (
    <div className="App">
      <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="home2" element={<Homepage2 />} />
          <Route path="home3" element={<Homepage3 />} />
          <Route path="home4" element={<Homepage4 />} />
          <Route path="hakkimizda" element={<AboutPage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="shop-single" element={<ShopSinglePage />} />

          {/* Ramazan sayfaları CityProvider ile sarılıyor */}
          <Route
            path="/ramazan"
            element={
              <CityProvider>
                <RamazanPage />
              </CityProvider>
            }
          />
          <Route
            path="/ramazan/:city/:district"
            element={
              <CityProvider>
                <RamazanPage />
              </CityProvider>
            }
          />
          <Route
            path="/ramazan/:city"
            element={
              <CityProvider>
                <RamazanPage />
              </CityProvider>
            }
          />
          <Route path="kuran-i-kerim" element={<QuranPage />} />
          <Route path="event" element={<EventPage />} />
          <Route path="event" element={<EventPage />} />
          <Route path="event-single" element={<EventPageSingle />} />
          <Route path="donate" element={<DonatePage />} />
          <Route path="blog1" element={<BlogPage />} />
          <Route path="blog-left" element={<BlogPageLeft />} />
          <Route path="blog" element={<BlogPageFullwidth />} />
          <Route path="blog-details" element={<BlogDetails />} />
          <Route path="blog-details-left" element={<BlogDetailsLeftSiide />} />
          <Route path="blog/yazi" element={<BlogDetailsFull />} />
          <Route path="404" element={<ErrorPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
      </HelmetProvider>
    </div>
  );
};

export default AllRoute;
