import React, { useState, useEffect, useContext } from 'react';
import { CityContext } from '../CityContext/CityContext';
import './style.css';

// Yardımcı: "DD-MM-YYYY" formatındaki string'i Date'e çevirir.
const parseDate = (dateStr) => {
  const [day, month, year] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

// Yardımcı: "DD-MM-YYYY" formatındaki tarihi, örneğin "26 Mart 2025" şeklinde döndürür.
const formatDateDisplay = (dateStr) => {
  const date = parseDate(dateStr);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('tr-TR', options);
};

const Service = () => {
  const { selectedCity, selectedDistrict } = useContext(CityContext);
  const [imsakSchedule, setImsakSchedule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Özel günler dizisi: tarih formatı "DD-MM-YYYY" olmalıdır.
  const specialEvents = [
    { date: "26-03-2025", event: "Kadir Gecesi" },
    { date: "29-03-2025", event: "Arefe Günü" },
    { date: "30-03-2025", event: "Ramazan Bayramı" }
  ];

  // API çağrısı için yardımcı fonksiyon
  const fetchCalendar = async (month, year) => {
    const response = await fetch(
      `https://api.aladhan.com/v1/calendarByCity?city=${selectedCity}&country=Turkey&method=13&month=${month}&year=${year}`
    );
    const data = await response.json();
    if (data.code === 200) {
      return data.data;
    } else {
      throw new Error("API Error: " + data.status);
    }
  };

  useEffect(() => {
    if (!selectedCity || !selectedDistrict) return;

    const fetchSchedule = async () => {
      try {
        setLoading(true);
        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const currentYear = now.getFullYear();

        // Geçerli ayın takvim verilerini çek
        let calendarData = await fetchCalendar(currentMonth, currentYear);

        // Bugünden itibaren geçerli günleri filtrele
        let schedule = calendarData.filter(dayData => {
          const day = parseInt(dayData.date.gregorian.day, 10);
          return day >= now.getDate();
        });

        // Eğer bu ayın kalan günleri 30'dan azsa, sonraki ayın verilerini de çek
        if (schedule.length < 30) {
          let nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
          let nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;
          let nextCalendar = await fetchCalendar(nextMonth, nextYear);
          schedule = schedule.concat(nextCalendar);
        }

        // Tarihlere göre sıralayıp, bugünden itibaren 30 günü al
        schedule.sort((a, b) => {
          const dateA = parseDate(a.date.gregorian.date);
          const dateB = parseDate(b.date.gregorian.date);
          return dateA - dateB;
        });
        const next30Days = schedule.slice(0, 30);
        setImsakSchedule(next30Days);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err);
        setLoading(false);
      }
    };

    fetchSchedule();
  }, [selectedCity, selectedDistrict]);

  if (loading)
    return (
      <div className="service-area section-padding">
        <div className="container">Loading...</div>
      </div>
    );
  if (error)
    return (
      <div className="service-area section-padding">
        <div className="container">Error: {error.message}</div>
      </div>
    );

  // "+03" gibi ek bilgileri kaldıran yardımcı fonksiyon
  const formatTime = (timeStr) => {
    if (!timeStr) return '';
    return timeStr.split(' ')[0];
  };

  return (
    <div className="service-area section-padding">
      <div className="container">
        <h1>{selectedCity} {selectedDistrict} 30 Günlük İmsak & Namaz Vakitleri</h1>
        <div className="table-container">
          <table className="imsak-schedule">
            <thead>
              <tr>
                <th>Tarih</th>
                <th>İmsak</th>
                <th>Sabah</th>
                <th>Öğle</th>
                <th>İkindi</th>
                <th>Akşam</th>
                <th>Yatsı</th>
              </tr>
            </thead>
            <tbody>
              {imsakSchedule.map((dayData, index) => {
                const dateStr = dayData.date.gregorian.date; // "DD-MM-YYYY"
                const { Fajr, Sunrise, Dhuhr, Asr, Maghrib, Isha } = dayData.timings;
                // Özel gün var mı kontrol et (tarih tam eşleşiyorsa)
                const specialEvent = specialEvents.find(ev => ev.date === dateStr);
                return (
                  <React.Fragment key={index}>
                    {specialEvent && (
                      <tr className="special-event">
                        <td colSpan="7">
                          {specialEvent.event} ({formatDateDisplay(specialEvent.date)})
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>{dateStr}</td>
                      <td>{formatTime(Fajr)}</td>
                      <td>{formatTime(Sunrise)}</td>
                      <td>{formatTime(Dhuhr)}</td>
                      <td>{formatTime(Asr)}</td>
                      <td>{formatTime(Maghrib)}</td>
                      <td>{formatTime(Isha)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Service;
