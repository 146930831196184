import React from 'react';
import './style.css';

const Support = () => {
  return (
    <section className="support-curved-area">
      <div className="support-curved-bg">
        {/* İsteğe bağlı ufak simgeler, mutlak konumlu */}
        <span className="floating-icon icon-1"></span>
        <span className="floating-icon icon-2"></span>
        <span className="floating-icon icon-3"></span>

        <div className="support-curved-content">
          <h1>Aradığınız şehrin İftar ve Sahur saatlerini kolayca bulun</h1>
          <p>
            2025 Ramazan ayı için iftar saatleri ve sahur saatlerini
            <br />
            kolayca öğrenebilirsiniz. Ayrıca Trabzon, Mersin, Sakarya, Eskişehir
            ve Diyarbakır gibi şehirler için ve diğer tüm şehirler için iftar saatleri için sol altta bulunan "Şehir Seç" Butonunu kullanabilirsiniz. İhtiyacınız olan bilgiyi kolayca bulun!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Support;
