import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo.png'
import Newsletter from '../Newsletter'
import './style.css'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <div className={`wpo-ne-footer ${props.footerClass}`}>
        <Newsletter/>
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="" />
                                </div>
                                <p>Vaktinizi bilin, İbadetinizi Yaşayın </p>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="https://facebook.com/imsak.net"><i className="ti-facebook"></i></Link></li>
                                    <li><Link onClick={ClickHandler} to="https://x.com/imsak.net"><i className="ti-twitter-alt"></i></Link></li>
                                    <li><Link onClick={ClickHandler} to="https://instagram.com/imsak.net"><i className="ti-instagram"></i></Link></li>
                                    <li><Link onClick={ClickHandler} to="mailto:info@imsak.net"><i className="ti-google"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Hizmetler</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/service-single">İftar / İmsak Vakti</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single">İmsakiye</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service">Namaz Saatleri</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Hadisler ve Dualar</Link></li>
                                    <li><Link onClick={ClickHandler} to="/event">Görülmesi Gereken Camiler</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Kullanışlı Linkler</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">Hakkımızda</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single">İletişim</Link></li>
                                    <li><Link onClick={ClickHandler} to="/event">Mail Aboneliği</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">Profil</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 offset-lg-1 col-md-6 col-12">
                            <div className="widget market-widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>İletişim </h3>
                                </div>
                                <p>Tüm sorunlarınız ve önerileriniz için bizimle iletişime geçebilirsiniz.</p>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi ti-location-pin"></i>Şişli, İstanbul, Türkiye</li>
                                        <li><i className="fi flaticon-call"></i>+905553555555</li>
                                        <li><i className="fi flaticon-envelope"></i>info@imsak.net</li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='altYazi'>
                            <h2>Türkiye İlleri İftar Saatleri</h2>
                            <p> Adana Adıyaman Afyonkarahisar Ağrı Aksaray Amasya Ankara Antalya Ardahan Artvin Aydın Balıkesir Bartın Batman Bayburt Bilecik Bingöl Bitlis Bolu Burdur Bursa Çanakkale Çankırı Çorum Denizli Diyarbakır Düzce Edirne Elazığ Erzincan Erzurum Eskişehir Gaziantep Giresun Gümüşhane Hakkari Hatay İçel (Mersin) Iğdır Isparta İstanbul İzmir Kahramanmaraş Karabük Karaman Kars Kastamonu Kayseri Kilis Kırıkkale Kırklareli Kırşehir Kocaeli Konya Kütahya Malatya Manisa Mardin Muğla Muş Nevşehir Niğde Ordu Osmaniye Rize Sakarya Samsun Şanlıurfa Siirt Sinop Şırnak Sivas Tekirdağ Tokat Trabzon Tunceli Uşak Van Yalova Yozgat Zonguldak </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright">&copy; 2024 İmsak.net Tüm Hakları Saklıdır.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
} 

export default Footer;