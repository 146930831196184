import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './style.css';

const FloatingCityBubble = ({
  selectedCity,
  selectedDistrict,
  cities,
  onCityChange,
  onDistrictChange,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  };

  return ReactDOM.createPortal(
    <div className="floating-city-bubble-container">
      <div className="floating-city-bubble" onClick={toggleDropdown}>
        <i className="fi flaticon-city"></i>
        <span className="bubble-text">Şehir Seç</span>
      </div>
      {showDropdown && (
        <div className="floating-city-dropdown">
          <select
            value={selectedCity}
            onChange={(e) => {
              onCityChange(e);
              setShowDropdown(false);
            }}
          >
            {Object.keys(cities).map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          <select
            value={selectedDistrict}
            onChange={(e) => {
              onDistrictChange(e);
              setShowDropdown(false);
            }}
          >
            {cities[selectedCity].map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>,
    document.body
  );
};

export default FloatingCityBubble;
