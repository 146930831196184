// src/pages/HomePage/index.js
import React, { Fragment } from 'react';
import { CityProvider } from '../../components/CityContext/CityContext';
import Navbar from '../../components/Navbar';
import Hero from '../../components/hero';
import About from '../../components/about';
import Service from '../../components/Service';
import Pilars from '../../components/Pilars';
import Support from '../../components/Support';
import BlogSection from '../../components/BlogSection';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import abimg from '../../images/about3.png';
import blog1 from '../../images/blog/blog1.webp';
import blog2 from '../../images/blog/blog2.webp';
import blog3 from '../../images/blog/blog3.webp';
import hero1 from '../../images/slider/img-3.png';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "İmsak.net",
    "url": "https://imsak.net/",
    "description": "Türkiye'nin tüm illeri ve ilçeleri için sahur ve iftar saatleri. Günlük imsakiye bilgileri burada!",
    "publisher": {
      "@type": "Organization",
      "name": "İmsak.net",
      "url": "https://imsak.net",
      "logo": "https://imsak.net/logo.png"
    },
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://imsak.net/search?q={query}",
      "query-input": "required name=query"
    }
  };
  return (
    <CityProvider>
       <div>
            <Helmet>
           <title>İmsak.net - Ana Sayfa | Vaktinizi Bilin İbadetinizi Yaşayın</title>
           <meta name="description" content="Türkiye’nin tüm illeri ve ilçeleri için güncel imsakiye bilgileri. Sahur ve iftar vakitlerini anında öğrenmek için İmsak.net’i ziyaret edin." />
           <meta name="keywords" content="imsak.net, İftara ne kadar kaldı, iftar vakti, sahur vaktine kadar kaldı, sahur vakti" />
           <meta name="author" content="imsak.net" />
           <meta name="robots" content="index, follow" />
           <link rel="canonical" href="https://imsak.net/" />
           <meta property="og:locale" content="tr_TR" />
           <meta property="og:type" content="website" />
           <meta property="og:title" content="İmsak.net - Ana Sayfa | Vaktinizi Bilin İbadetinizi Yaşayın" />
           <meta property="og:description" content="Türkiye’nin tüm illeri ve ilçeleri için güncel imsakiye bilgileri. Sahur ve iftar vakitlerini anında öğrenmek için İmsak.net’i ziyaret edin." />
           <meta property="og:url" content="https://imsak.net/" />
           <meta property="og:site_name" content="İmsak.net" />
           <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
        </div>
      <Fragment>
        <Navbar />
        <Hero HeroStyleClass={'hero-style-2'} />
        <Support />
        <About aboutImg={abimg} />
        <Service />
        <Pilars />
        <BlogSection blogImg1={blog1} blogImg2={blog2} blogImg3={blog3} />
        <Footer />
        <Scrollbar />
      </Fragment>
    </CityProvider>
  );
};

export default HomePage;
