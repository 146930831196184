// src/pages/RamazanPage.js
import React, { useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/Navbar';
import Hero from '../../components/hero';
import Support from '../../components/Support';
import Support1 from '../../components/Support1';
import Service from '../../components/Service';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import hero1 from '../../images/slider/img-3.png';
import { CityContext } from '../../components/CityContext/CityContext';

const RamazanPage = () => {
  const { city, district } = useParams();
  const { cities, setSelectedCity, setSelectedDistrict } = useContext(CityContext);

  // URL'den gelen değerleri güvenli şekilde kontrol et
  const formattedCity = city ? city.charAt(0).toUpperCase() + city.slice(1).toLowerCase() : "";
  const formattedDistrict = district ? district.charAt(0).toUpperCase() + district.slice(1).toLowerCase() : "";

  useEffect(() => {
    console.log("Gelen Parametreler:", city, district); // Hata ayıklamak için ekledik
    console.log("Şehirler JSON:", cities); // JSON verisini kontrol etmek için
    console.log("✅ Meta Title:", pageTitle);
    console.log("✅ Meta Description:", pageDescription);
    if (formattedCity) {
      setSelectedCity(formattedCity);

      if (formattedDistrict) {
        setSelectedDistrict(formattedDistrict);
      } else {
        // Eğer ilçe yoksa, JSON'dan gelen ilk ilçeyi belirle
        const defaultDistrict = cities[formattedCity]?.[0] || "Merkez"; // Varsayılan değer eklendi
        setSelectedDistrict(defaultDistrict);
      }
    }
  }, [formattedCity, formattedDistrict, setSelectedCity, setSelectedDistrict, cities]);

  // Eğer `city` undefined gelirse varsayılan bir mesaj kullan
  const pageTitle = formattedCity
    ? formattedDistrict
      ? `İmsak.net|${formattedCity} ${formattedDistrict} İftar, Sahur ve Teravih Saatleri `
      : `İmsak.net| ${formattedCity} İftar, Sahur ve Teravih Saatleri`
    : "İmsak.net| İftar, Sahur ve Teravih Saatleri";

  const pageDescription = formattedCity
    ? formattedDistrict
      ? `${formattedCity} ${formattedDistrict} için iftar ve sahur saatlerini öğrenin! Günlük namaz vakitleri, iftar saatleri ve sahur zamanları burada. Ramazan içerikleri ilginizi çekebilir`
      : `${formattedCity} için iftar ve sahur saatlerini öğrenin! Günlük namaz vakitleri, iftar saatleri ve sahur zamanları burada. Ramazan içerikleri ilginizi çekebilir!`
    : "Şehriniz için iftar ve sahur saatlerini öğrenin! Günlük namaz vakitleri, iftar saatleri ve sahur zamanları burada. Ramazan içerikleri ilginizi çekebilir.";
   
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": `${formattedCity} ${formattedDistrict} İçin İmsakiye`,
      "description": pageDescription,
      "url": `https://imsak.net/ramazan/${city}/${district}`,
      "publisher": {
        "@type": "Organization",
        "name": "İmsak.net",
        "url": "https://imsak.net"
      }
    };
  return (
    <Fragment>
      {/* SEO Meta Etiketleri */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <link rel="canonical" href="https://imsak.net/ramazan/${city}/${district}" />

      </Helmet>

      <Navbar />
      <Hero HeroStyleClass="hero-style-2" heroImg={hero1} />

      <Support />
      <Service />
      <Support1 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default RamazanPage;
